
export  default {
    code:0,
    isLogin:false,
    //用户信息
    userInfo:false,
    //表单数据
    formdata:{
        id:0,
        activity_name: "",
        enroll_type: "",
        address: "",
        start_time: "",
        end_time: "",
        enroll_end_time: "",
        activity_category_id: 0,
        is_recom_switch: false,
        is_comment_switch: false
    },
    //发布的表单数据
    add_formdata:{
        id:0,
        activity_name: "",
        enroll_type: "",
        address: "",
        start_time: "",
        end_time: "",
        enroll_end_time: "",
        activity_category_id: 0,
        is_recom_switch: false,
        is_comment_switch: false
    },
    sponsor_info:false,
    wxInitSign:false,//微信是否签名
    token:false,//微信是否签名,
	systemInfo: false
}

