var postfix = '_activity'; // 缓存前缀
/**  
 * 设置缓存   
 * @param  {[type]} k [键名]  
 * @param  {[type]} v [键值]  
 * @param  {[type]} t [时间、单位秒]  
 */  
function put(k, v,t) {
    window.localStorage.setItem(k,v);
    let seconds = parseInt(t);
    if (seconds > 0) {
        let timestamp = Date.parse(new Date());
        timestamp = timestamp / 1000 + seconds;
        window.localStorage.setItem(k + postfix,timestamp+"");
    } else {
        window.localStorage.removeItem(k + postfix);
    }
}  

function get(k, def) {

    let deadtime = parseInt(window.localStorage.getItem(k + postfix));
    if (deadtime) {  //并且有网络的时候才判断时间
        if (parseInt(deadtime) < Date.parse(new Date()) / 1000) {
            if (def) {
                return def;
            } else {
                return false;
            }
        }
    }
    var res = window.localStorage.getItem(k);
    if (res) {
        return res;
    } else {
        if (def == undefined  || def == "") {
            def = false;
        }
        return def;
    }
}

function remove(k) {  
   window.localStorage.removeItem(k);
    window.localStorage.removeItem(k + postfix);
}  

/**  
 * 清理所有缓存  
 * @return {[type]} [description]  
 */  
function clear() {   
  window.localStorage.clear()
}

export default {
    put,
    get,
    remove,
    clear,
}
