import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/index';
import wx from "@/request/utils/wechat"; //微信JSSDK
import cache from '@/request/utils/cache';

Vue.use(Router);

/*路径布局  
activity 是活动详情 以及相关页面（报名列表、评论、邀请函等）   
add 放表单提交 (添加 、编辑)的页面
user 放用户界面的功能以及我的活动、收藏、活动管理等等
*/

const routes = [{
		path: '*',
		redirect: '/home',
		name: 'home',
		wechatAuth: true, // 如果此路由需要微信授权请设置为true,默认为false
		hideMenuItem: true // 如果此路由需要隐藏微信基础菜单的,设置为true
	},
	{
		path: '/user',
		component: () => import('./view/user'),
		meta: {
			title: '会员中心',
			wechatAuth: true,

			hideMenuItem: true
		}
	},
	{
		path: '/user/edit/userInfo',
		component: () => import('./view/user/edit/userInfo'),
		meta: {
			title: '个人资料',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/user/edit/info',
		component: () => import('./view/user/edit/info'),
		meta: {
			title: '编辑信息',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/user/attestation/orderlist',
		component: () => import('./view/user/attestation/orderlist'),
		meta: {
			title: '订单列表',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/user/attestation/mycollect',
		component: () => import('./view/user/attestation/mycollect'),
		meta: {
			title: '我的收藏',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/enroll',
		component: () => import('./view/activity/enroll/index'),
		meta: {
			title: '报名',
			wechatAuth: true,
			hideMenuItem: true
		}
	}, {
		path: '/activity/enroll/pay',
		component: () => import('./view/activity/enroll/pay'),
		meta: {
			title: '报名支付',
			wechatAuth: true,
			hideMenuItem: true
		}

	}, {
		path: '/activity/enroll/message',
		component: () => import('./view/activity/enroll/message'),
		meta: {
			title: '报名信息',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/enroll/moremessage',
		component: () => import('./view/activity/enroll/moremessage'),
		meta: {
			title: '更多报名信息',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/enroll/myorder',
		component: () => import('./view/activity/enroll/myorder'),
		meta: {
			title: '我的订单',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/enroll/ticket',
		component: () => import('./view/activity/enroll/ticket'),
		meta: {
			title: '报名信息',
			wechatAuth: true,
			hideMenuItem: true
		}
	}, {
		path: '/activity/member',
		component: () => import('./view/activity/member'),
		meta: {
			title: '活动报名列表',
			wechatAuth: true,
			hideMenuItem: true
		}
	}, {
		path: '/activity/comment/:activity_id',
		component: () => import('./view/activity/comment'),
		meta: {
			title: '评论列表',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity',
		component: () => import('./view/activity'),
		meta: {
			title: '活动详情',
			wechatAuth: true,
			hideMenuItem: false
		}
	},
	{
		path: '/activity/invitation',
		component: () => import('./view/activity/invitation'),
		meta: {
			title: '邀请函',
			wechatAuth: true
		}
	}, 
	{
		path: '/user/manage/operate/scan',
		component: () => import('./view/user/manage/operate/scan'),
		meta: {
			title: '扫验订单',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/user/manage/operate/getticket',
		component: () => import('./view/user/manage/operate/getticket'),
		meta: {
			title: '获取电子票',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/user/manage/operate/checkticket',
		component: () => import('./view/user/manage/operate/checkticket'),
		meta: {
			title: '手动验票',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/user/wxauth',
		component: () => import('./view/user/wxauth'),
		meta: {
			title: '授权登录',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/user/manage/operate/scanInfo',
		component: () => import('./view/user/manage/operate/scanInfo'),
		meta: {
			title: '活动票据',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/user/attestation/comment',
		component: () => import('./view/user/attestation/myComment'),
		meta: {
			title: '我的评论',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/marketing/group/detail',
		component: () => import('./view/activity/marketing/group/detail'),
		meta: {
			title: '拼团详情',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/marketing/group/more',
		component: () => import('./view/activity/marketing/group/more'),
		meta: {
			title: '更多拼团',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/marketing/group/toOrder',
		component: () => import('./view/activity/marketing/group/toOrder'),
		meta: {
			title: '确认订单',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/marketing/group/order',
		component: () => import('./view/activity/marketing/group/order'),
		meta: {
			title: '拼团订单',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/marketing/group/invite',
		component: () => import('./view/activity/marketing/group/invite'),
		meta: {
			title: '参与拼团',
			wechatAuth: true,
			hideMenuItem: false
		}
	},
	{
		path: '/activity/marketing/group/orderDetail',
		component: () => import('./view/activity/marketing/group/orderDetail'),
		meta: {
			title: '订单详情',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/marketing/hot/detail',
		component: () => import('./view/activity/marketing/hot/detail'),
		meta: {
			title: '爆款详情',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/marketing/hot/more',
		component: () => import('./view/activity/marketing/hot/more'),
		meta: {
			title: '更多爆款',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/marketing/hot/toOrder',
		component: () => import('./view/activity/marketing/hot/toOrder'),
		meta: {
			title: '确认订单',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/marketing/hot/order',
		component: () => import('./view/activity/marketing/hot/order'),
		meta: {
			title: '爆款订单',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/marketing/hot/orderDetail',
		component: () => import('./view/activity/marketing/hot/orderDetail'),
		meta: {
			title: '订单详情',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/activity/marketing/pay_success',
		component: () => import('./view/activity/marketing/pay_success'),
		meta: {
			title: '支付成功',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/home',
		component: () => import('./view/home/index'),
		meta: {
			title: '主页',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/home/activity_list',
		component: () => import('./view/home/activity_list'),
		meta: {
			title: '活动列表',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/home/nearby',
		component: () => import('./view/home/nearby'),
		meta: {
			title: '同城活动',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
	{
		path: '/payment/method',
		component: () => import('./view/payment/method'),
		meta: {
			title: '订单支付',
			wechatAuth: true,
			hideMenuItem: true
		}
	},
];

// add route path
routes.forEach(route => {
	route.path = route.path || '/' + (route.name || '');
});


const router = new Router({
	mode: 'history',
	routes
});

router.afterEach((to, from) => {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	let hideMenuItem = to.meta.hideMenuItem; //是否隐藏微信菜单
	var url = window.location.host + to.fullPath;
	window.authUrl = url
	//登录后进行签名操作
	// wx.init(url,res => {
	// 	if (hideMenuItem) {
	// 		//隐藏所有基础菜单
	// 		wx.menuHide();
	// 	}
	// })
});


router.beforeEach((to, from, next) => {
	const title = to.meta && to.meta.title;
	let wechatAuth = to.meta.wechatAuth;
	if (title) {
		document.title = title;
	}
	if (!wechatAuth) {
		next();
		return;
	}
	if (window.__wxjs_is_wkwebview && to.path !== location.pathname) {
	    location.assign(to.fullPath)
	}
	let token = cache.get("token");
	console.log(token)
	
	if (process.env.NODE_ENV == 'development') {
		//开发环境直接登录
		console.log(123)
		store.dispatch("login", "用户Token");
		next();
		return;
	}
	if (token) {
		console.log(456)
		store.dispatch("login", token);
		next();
		return;
	} else {
		//授权获取信息
		let code = getQueryVariable('code');


		if (code) {
			if (to.path == '/user/wxauth') {
				to.query.code = code;
				next();
			}
		} else {
			store.dispatch("wechatLogin", to.fullPath);
		}
	}

});

function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (pair[0] == variable) {
			return pair[1];
		}
	}
	return (false);
}


export {
	router
};
