<template>
  <div>
       	<van-dropdown-menu>
			<van-dropdown-item title="筛选" ref="item">
                <!-- 时间 -->
				<div class="APP-field">
					<div @click="showStartTime = true">{{time.start_time}}</div>
					<span style="font-weight: 900;font-size: 20px;">-</span>
					<div @click="showEndTime = true">{{time.end_time}}</div>
				</div>
                <!-- 搜索栏 -->
				<van-search placeholder="请输入姓名或手机号" v-model="keyword" v-if="!hideSearch"/>
                <!-- 额外条件 -->
                <van-grid :border="false"  :column-num="4" :gutte="10" class="mt10 mb10" v-if="filterArray">
                     <van-grid-item class="filter-item" v-for="(item,index) in filterArray" :key="index" >
                       <p :class="['text-base',selectedData.index == index?'hover':'']" @click="toselect(item,index)">{{item.text}}</p>
				    </van-grid-item>
                </van-grid>
                

                <!-- 按钮 -->
				<div class="top-button-wrap">
					<van-button type="default" class="mr5" size="small" @click="toClean">清除</van-button>
					<van-button type="info" size="small" @click="toCommit">确认</van-button>
				</div>
			</van-dropdown-item>
		</van-dropdown-menu>

        


        
        <!-- 时间picker -->
        <van-popup v-model="showStartTime" position="bottom">
			<van-datetime-picker v-model="currentDate" type="datetime" :min-date="minDate" :max-date="maxDate" @cancel="showStartTime = false"
			 @confirm="onStartTime" />
		</van-popup>
		<van-popup v-model="showEndTime" position="bottom">
			<van-datetime-picker v-model="currentDate" type="datetime" :min-date="minDate" :max-date="maxDate" @cancel="showEndTime = false"
			 @confirm="onEndTime" />
		</van-popup>
  </div>
</template>

<script>
// 封装过滤头部组件

import {DropdownMenu,DropdownItem,DatetimePicker,Picker,Popup,Search,Button,Grid,GridItem} from 'vant';
export default {
    name:'filterTop',
    components:{
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Search.name]: Search,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [DatetimePicker.name]: DatetimePicker,
            [Button.name]:Button,
            [Grid.name]:Grid,
            [GridItem.name]:GridItem
    },
    props:{
       filterArray:null,    //额外条件  [{text:'未发货',status:1},{text:'已发货',status:2}]
       hideSearch:false   //隐藏搜索栏
    },
    data(){
        return{
                keyword: '',
				currentDate: new Date(),
				minDate: new Date(2019,1,1),
				maxDate: new Date(2099, 12, 1),
				showStartTime: false,
				showEndTime: false,
				time: {
					start_time: "开始时间",
					end_time: "结束时间"
                },
                List:[{text:'未发货',status:1},{text:'已发货',status:2},{text:'未收货',status:3},{text:'已收货',status:4},{text:'已退货',status:6}],
                selectedData:{
					index:'',
					item:''
				} //选中条件

        }
	},
	mounted:function(){

		//多选
		// if(this.filterArray !=''){
		//   this.filterArray.forEach((item,index)=> {
		// 	this.$set(this.filterArray[index],'selected',false);
		//   });
		// }

	},
    methods:{
           toselect(item,index){
			    //单选
                let data = {
                    index:index,
                    item:item
				}
      			this.selectedData = data;
			
	
				//多选
				// this.filterArray[index].selected = !this.filterArray[index].selected;	
				
           },
           toClean(){
				//单选
				this.selectedData = [];
			    //多选
                // this.filterArray.forEach((item,index)=>{
				// 	this.filterArray[index].selected = false;
				// })
                this.time.start_time = '开始时间';
                this.time.end_time = '结束时间';
                this.keyword = '';

           },
           toCommit(){
				let data = [];
				var mytime = {
					start_time:this.time.start_time,
					end_time:this.time.end_time
				};
			
				if(mytime.start_time =='开始时间'){
					mytime.start_time ='';
				}
				if(mytime.end_time == '结束时间'){
					mytime.end_time = '';
				}
				this.$refs.item.toggle(false);
				this.$set(data,'time',mytime);
				

				//多选
				// this.selectedData = [];
				// this.filterArray.forEach((item,index)=>{
                //    if(item.selected == true){
				// 	   let data = {
                //             'index':index,
                //             'item':item
				//        }
				// 	   this.selectedData.push(data);
				//    }
				// })
                //单选		
                this.$set(data,'selectedData',this.selectedData);
                this.$set(data,'keyword',this.keyword);
                this.$emit('get',data);
           },
           onStartTime: function(val) {
				let _self = this;
				this.showStartTime = false;
				this.time.start_time = this.dateFormat(val);
				setTimeout(function() {
					_self.$refs.item.toggle(true);
				}, 10)
			},
			onEndTime: function(val) {
				let _self = this;
				this.showEndTime = false;
				this.time.end_time = this.dateFormat(val);
				setTimeout(function() {
					_self.$refs.item.toggle(true);
				}, 10)
			},
			//时间格式化函数，此处仅针对yyyy-MM-dd hh:mm:ss 的格式进行格式化
			dateFormat: function(time) {
				var date = new Date(time);
				var year = date.getFullYear();
				/* 在日期格式中，月份是从0开始的，因此要加0
				 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
				 * */
				var month =
					date.getMonth() + 1 < 10 ?
					"0" + (date.getMonth() + 1) :
					date.getMonth() + 1;
				var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
				var hours =
					date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
				var minutes =
					date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
				// 拼接
				return year + "-" + month + "-" + day + " " + hours + ":" + minutes;
			}
    }

}
</script>

<style lang="less" scouped>
  .top-button-wrap {
    display: flex;
    flex-flow: row nowrap;
    padding: 10px 3px;
    justify-content: center;

    button {
      width: 100px;
    }
  }
.van-search{
    width: 96%;
    margin: 0 auto;
    border: 0.02667rem solid #e7e7e7;
	background-color: #FFFFFF;
}
	.APP-field {
		width: 96%;
		padding: 2%;
		font-size: 12px;
		color: #757575;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
	}

	.APP-field>div {
		width: 47%;
		text-align: center;
		padding: 10px 0;
		border: 1px solid #e7e7e7;
	}
.filter-item {
	margin: 2px 0;
    
    .van-grid-item__content{
		padding: 5px ;
		
		
        p{
        border:  0.02667rem solid #e7e7e7;
        font-size: 14px;
        color:#646566;
        padding: 5px 13px;
         
        }
        .hover{
               border-color: #1989fa;
               color: #1989fa;
           }
        

    }

}
</style>