

<template>
  <div :class="layer?'loading layer':'loading'" v-if="canshow">
    <!-- // 封装loading组件 -->
    <!-- <img src="./../assets/images/loading.jpg" alt=""> -->
    <!-- <p>{{text}}</p> -->
    <div class="wrap">
       <van-loading size="40px" vertical color="white">{{text}}</van-loading>
    </div>

  </div>
</template>
 
<script>
import { Loading } from 'vant';

  export default {
    name: 'myloading',
    components:{
        [Loading.name]:Loading
    },
    data () {
      return {
          canshow:false
      }
    },
    // props: {
    //   canshow:false,
    //   text:{
    //     type:String,
    //     default:'加载中...'
    //   },
    //   layer:Boolean
    // },
    mounted:function(){
        //   //不写也可以
        //  if(this.show){
        //    this.show = this.show;
        //  }
        //  if(this.tips!=''){
        //    this.text = this.tips
        //  }
        //  if(this.layer!=''){
        //    this.showlayer =  this.layer
        //  }
    }
    
  }
</script>
<style>
  .loading{
    position: fixed;
    top:0;
    left:0;
    z-index:121;
    width: 100%;
    height: 100%;
    background: #f8f8f8;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.layer{
  background-color: rgba(0,0,0,0.4);
}
  
  .wrap{
    width: 130px;
    padding: 20px 10px;
    background-color: rgba(0,0,0,0.7);
    border-radius: 4%;
  }
  .loading img{
  	width: 1rem;
  	height: 2rem;
    margin:7.5rem auto;
  }
  .van-loading__text{
    color: white;
  }
</style>