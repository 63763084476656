import storage from '@/request/storage';

//登录
function login(state, token) {
    //改变登录态
    state.isLogin = true;
    state.token = token;
}

// 更新用户信息
function UpdateUserInfo(state, userInfo) {
    state.userInfo = userInfo;
}

//更新当前编辑活动的id
function UpdateActivityId(state, id) {
    state.activity_id = id;
}

//保存未提交表格的数据
function UpdateFormData(state, data) {
    state.formdata = data;
}
function UpdateAddFormData(state, data) {
    state.add_formdata = data;
}


function UpdateSponsor(state, data) {
    state.sponsor_info = data;
}

function UpdateWxInitSign(state,sign) {
    state.wxInitSign = sign;
}

function loginOut(state) {
    state.token = false;
    state.isLogin = false;
}

function UpdateSystemInfo(state, systemInfo) {
    state.systemInfo = systemInfo;
	this._vm.$cache.put("systemInfo", JSON.stringify(systemInfo));
}


export default {
    login,
    UpdateUserInfo,
    UpdateActivityId,
    UpdateFormData,
    UpdateAddFormData,
    UpdateSponsor,
    UpdateWxInitSign,
    loginOut,
	UpdateSystemInfo
}
