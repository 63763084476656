/*
 * @Author: HJ-DENG
 * @Date: 2023-04-28 10:59:36
 * @LastEditTime: 2023-06-16 15:19:26
 * @Description: 
 * 1205490144@qq.com
 */
import Vue from 'vue';
import App from './App';
import { router } from './router';
import VuePageStack from 'vue-page-stack';

import 'amfe-flexible';
import './assets/font/iconfont.css';
import './assets/css/common.css';
import './assets/css/animate.css';

import {Lazyload,Notify,Toast,Dialog,Image,Cell,CellGroup} from 'vant';
//axios 请求
import axios from 'axios';
//全局挂载,注册组件
import NoData  from '@/components/NoData';
import myloading from '@/components/myloading/index';
import store from '@/store/index';
import storage from "@/request/storage.js";
import echarts from "echarts";
import filterTop from '@/components/filter';
import Vconsole from 'vconsole'
import cache from '@/request/utils/cache.js';

//地图
import Bmap from '@/components/Bmap.vue';
Vue.component('Bmap',Bmap);


//富文本
import 'tinymce/skins/ui/oxide/skin.css'


Vue.component('no-data',NoData);
Vue.component('filter-top',filterTop);


// 页面管理栈
Vue.use(VuePageStack, { router }); 

Vue.use(Lazyload);
Vue.use(Notify);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(myloading);
Vue.use(Image);



Vue.prototype.$store = store;
Vue.prototype.$storage = storage;
Vue.prototype.$echarts = echarts;
Vue.prototype.$cache = cache;


var baseURl = '';
if (process.env.NODE_ENV == 'development') {    
  baseURl = 'http://hou.nntmkj.com/addons/suisunactivity';
} else if (process.env.NODE_ENV == 'debug') {    
  baseURl = 'http://hou.nntmkj.com/addons/suisunactivity';
} else if (process.env.NODE_ENV == 'production') {    
  baseURl = 'http://hou.nntmkj.com/addons/suisunactivity/';
}

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
axios.defaults.baseURL = baseURl ; //关键代码

var instance = axios.create({
  baseURL:baseURl,
  headers:{"Content-Type":"multipart/form-data"}
})

Vue.config.productionTip=false;
Vue.prototype.instance = instance;
//  let vConsole = new Vconsole();
//  Vue.use(vConsole);

new Vue({
  router,
  Lazyload,
  el: '#app',
  render: h => h(App)
});


