
/**  
 * 设置缓存   
 * @param  {[type]} k [键名]  
 * @param  {[type]} v [键值]  
 * @param  {[type]} t [时间、单位秒]  
 */  
function put(k, v) {
    let v1 = JSON.stringify(v);  
    window.localStorage.setItem(k,v1);
}  

function get(k) {  
    var res =  JSON.parse(window.localStorage.getItem(k)); 


    if (res) {  
        return res;  
    }	
}  

function remove(k) {  
   window.localStorage.removeItem(k);  
}  

/**  
 * 清理所有缓存  
 * @return {[type]} [description]  
 */  
function clear() {   
  window.localStorage.clear()
}  

module.exports = {
    put: put,  
    get: get,  
    remove: remove,  
    clear: clear, 
}  