import vue from 'vue'
import myloadingComponent from './myloading.vue'

// 组件构造器，构造出一个 vue组件实例
const  myloadingConstructor = vue.extend(myloadingComponent);


const myloadingDom = new myloadingConstructor({
    el: document.createElement('div'),
    data () {
      return {
          
      }
    }
  })
// 添加节点
document.body.appendChild(myloadingDom.$el)


function show( text,layer, duration = 3000 ) {
    myloadingDom.canshow = true;
    myloadingDom.text = text;
    if(!layer){
      layer = true;
    }
    myloadingDom.layer = layer;
  // 过渡时间
//   setTimeout(() => {
//     myloadingDom.canshow = false
//   }, duration)
}
function hide(){
    myloadingDom.canshow = false;
}

// 全局注册
function registrymyloading () {
  vue.prototype.showLoading = show;
  vue.prototype.hideLoading = hide;
}

export default registrymyloading