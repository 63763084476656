<template>
 <transition name="fade" >
  <div class="mylayer" v-show="show" >
       <img class="img" src="@/assets/images/nodata.png" alt="">
  </div>
  </transition>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    msg: String
  },
  data() {
    return {
      show:false
    };
  },

  mounted:function(){
     let that =this;
        let timer = setTimeout(function(){
         that.show = true;
         clearTimeout(timer);

        },500);

    
  },
  activated() {
   
    
  },
  deactivated(){
    
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
 
    
.mylayer{
    position: fixed;
    width: 100%;
    height: 100%;
    // background-color: rgba(0,0,0,.1);
    background-color: transparent;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    z-index: 888;

 

    .img{
        width: 100px;
        // height: 120px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
    }
}


</style>
