import { get, post } from '@/request/http';

//活动模块
export const activity_detail = p=>post('activity/getone',p);               //获取活动的详情

export const activity_code = p=>post('activity/getcode',p);               //获取活动二维码
export const activity_list = p=>post('/activity/getlist',p);			  //活动列表
export const activity_addlike = p=>post('activity/addlike',p);            //活动点赞
export const activity_addcollect = p=>post('activity/addcollect',p);      //活动收藏
export const activity_collect_list = p=>post('activity/getcollect_list',p); //获取收藏列表
export const activity_enroll_list = p=>post('activity/getenroll_list',p)    //报名列表
export const activity_enroll_morelist = p=>post('activity/getenroll_all_list',p) //获取全部报名列表(分页)
export const add_comment = p=>post('activitycomment/add',p);               //添加/编辑评论
export const comment_list = p=>post('activitycomment/getlist',p);          //获取评论列表
export const del_comment = p=>post('activitycomment/del',p);               //删除评论
export const activity_enroll_message = p=>post('activity/getenroll_info',p);        //报名信息
export const activity_cardlist = p=>post('activitycard/getlist',p);        //活动卡券

//用户模块
export const get_gzh_user = p=>post('user/get_gzh_user',p);           //获取公众号用户信息
export const wx_signatur = p=>post('wxjsdk/signature',p);                  //获取微信jsdk 调用扫码
export const user_getspread = p=>post('user/getmy_spread',p);   //活动达人
export const user_mycollect = p=>post('user/getmy_collect',p);   //我的收藏
export const user_update = p=>post('user/update_user',p);   //更新用户信息
export const get_user_info = p=>post('user/getUser_FToken',p);   //获取用户信息
export const user_commnet = p=>post('activitycomment/getUser_comment',p);   //用户评论列表

//订单模块
export const pay_order = p=>post('activityorders/pay',p);                  //支付售票订单
export const close_enroll = p=>post('activitytickets/refund',p);           //关闭报名(售票)
export const activity_order_data = p=> post('activitytickets/getsum_data',p);//活动订单数据
export const ver_ticker = p=>post('activitytickets/ver',p);                //核销售票

// export const                                                            //下单后添加订单
export const get_ticket_detail = p=>post('activitytickets/getone',p);      //获取售票详情
export const update_ticket_detail = p=>post('activitytickets/update',p);   //修改售票详情
export const list_activitytickets = p=>post('activitytickets/getlist',p);    //获取该活动所有售票

// 售票模块
export const activity_mylist = p=>post('activitytickets/getmylist',p);//我的售票订单
export const tichets_info = p=>post('activitytickets/getele_tickets',p);//获取电子票
export const tichets_add = p=>post('activitytickets/add',p);//线上下单
export const tickets_buildorder = p=>post('/activitytickets/buildorder',p) //构建支付订单

export const live_order_pay = p=>post('pay/pay/id/',p)    //调用支付

//爆款
export const user_marketing_hot_goodslist = p=>get('/moldbaby/goodslist/activity/',p)    //获取商品列表
export const user_marketing_hot_goodsdetail = p=>get('/moldbaby/goodsdetail/id/',p)    //获取商品详情
export const user_marketing_hot_createorder = p=>post('/moldbaby/createorder/activity/',p)    //创建订单
export const user_marketing_hot_buildorder = p=>get('/moldbaby/buildorder/id/',p)    //构建支付订单
export const user_marketing_hot_orderlist = p=>get('/moldbaby/orderlist/activity/',p)    //订单列表
export const user_marketing_hot_orderdetail = p=>get('/moldbaby/orderdetail/id/',p)    //订单详情


//------------------------团购（用户）-----------------------------------------------
export const groupbuy_user_getgoodslist = p=>post('/groupbuy/getgoodslist',p) //获取团购商品列表
export const groupbuy_user_getgoodsdetail = p=>post('/groupbuy/getgoodsdetail',p) //获取团购商品详情
export const groupbuy_user_createorder = p=>post('/groupbuy/createorder',p) //创建订单
export const groupbuy_user_buildorder = p=>post('/groupbuy/buildorder',p) //构建支付订单
export const groupbuy_user_orderlist = p=>get('/groupbuy/orderlist',p) //获取团购订单列表
export const groupbuy_user_orderdetail = p=>get('/groupbuy/orderdetail',p) //获取团购订单详情

export const groupbuy_getgroupdetail = p=>post('/groupbuy/getgroupdetail',p) //拼团 组团信息


//----------------------主页---------------------------------------------------------
export const index_getlistbycity = p=>post('/index/getlistbycity', p)  //获取活动列表（LBS）
export const index_getlistbycategory = p=>post('/index/getlistbycategory', p)  //获取活动列表（行业类别）
export const index_getbanner = p=>get('/index/getbanner', p)  //获取轮播图
export const index_getcategory = p=>get('/index/getcategory', p)  //获取行业类型

export const getsysteminfo = p=>get('/index/index', p)  //获取系统信息