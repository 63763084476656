import {get_gzh_user,
get_user_info,
getsysteminfo
} from "@/request/api";


//更新登录状态 、token
function toLogin(context, data) {
    context.commit('login', data);
}

// 更新用户信息
function toUpdateUserInfo(context, Info) {
    context.commit('UpdateUserInfo', Info);
}

function toUpdateActivityId(context, id) {
    context.commit('UpdateActivityId', id);
}

//保存未编辑表单数据
function toUpdateFormData(context, data) {
    context.commit('UpdateFormData', data);
}
//保存未编辑表单数据
function toUpdateAddFormData(context, data) {
    context.commit('UpdateAddFormData', data);
}

//初始化用户信息
function initUserInfo(context) {
    get_user_info({}).then(res => {
        if (res.code == 1) {
            context.commit('UpdateUserInfo', res.data)
        }
    }).then(error => {
    });
}

//用户登录
function login(context, token) {
    if(context.state.token!=token){
        this._vm.$cache.put("token", token, 60 * 60 * 2);//两小时过期
        context.commit("login", token);//登录用户
    }
    if(!context.state.userInfo){
        //获取用户信息写入
        context.dispatch("initUserInfo");
    }
}

//退出登录
function loginOut(context) {
    this._vm.$cache.remove("token");
    context.commit("loginOut");
}

//登录
function wechatLogin(context, url) {
	if (!context.state.systemInfo){
		getsysteminfo({}).then(res => {
		    if (res.code == 1) {
		        context.commit('UpdateSystemInfo', res.data)
				context.dispatch('weLogin',url)
		    }
		})
	}else{
		context.dispatch('weLogin',url)
	}

}

function weLogin(context,url){
	let cur_path = 'http://' + window.location.host;
	let appId = context.state.systemInfo.appid;
	let redirectUrl = encodeURIComponent(cur_path + '/user/wxauth');
	if (url) {
	    sessionStorage.setItem('wxRedirectUrl', url);
	} else {
	    sessionStorage.setItem('wxRedirectUrl', window.location.href);
	}
	window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
}

function initSystemInfo(context) {
    getsysteminfo({}).then(res => {
        if (res.code == 1) {
            context.commit('UpdateSystemInfo', res.data)
        }
    })
}

export default {
    toLogin,
    toUpdateUserInfo,
    toUpdateActivityId,
    toUpdateFormData,
    toUpdateAddFormData,
    login,
    loginOut,
    initUserInfo,
    wechatLogin,
	initSystemInfo,
	weLogin
}